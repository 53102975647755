<template>
    <nav v-if="pageCount > 0" class="flex gap-4">
        <ButtonGroup>
            <Button
                :as="isFirstPage ? 'button' : 'a'"
                :disabled="isFirstPage"
                :href="linkHref(page - 1)"
                :aria-label="$t('PartCategory.PreviousPage')"
                @click.prevent="movePage(-1)">
                <i class="fass fa-chevron-left"></i>
            </Button>
            <Button
                v-for="pg in pageLinks"
                :key="pg.key"
                class="hidden md:block pt-2"
                :as="pg.href ? 'a' : 'button'"
                :fill="pg.number == page ? 'solid' : 'none'"
                :href="pg.href"
                @click.prevent="setPage(pg.number)">
                {{ pg.label }}
            </Button>
            <Button
                :as="isLastPage ? 'button' : 'a'"
                :disabled="isLastPage"
                :href="linkHref(page + 1)"
                :aria-label="$t('PartCategory.NextPage')"
                @click.prevent="movePage(1)">
                <i class="fass fa-chevron-right"></i>
            </Button>
        </ButtonGroup>

        <DropdownMenu>
            <DropdownMenuTrigger as-child>
                <Button class="group">
                    <span class="whitespace-nowrap">Page Size ({{ pageSize }})</span>
                    <i class="fass fa-chevron-right group-data-[state=open]:rotate-90 transition-transform"></i>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                <DropdownMenuItem v-for="size in pageSizeOptions" :key="size" @click="pageSize = size">
                    {{ size }}
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
        <slot />
    </nav>
</template>
<script setup lang="ts">
const MAX_NAV_PAGES = 10;
const MEDIAN_NAV_PAGES = MAX_NAV_PAGES / 2;

const pageSize = defineModel<number>("size", { required: true });
const page = defineModel<number>("page", { required: true });
const route = useRoute();
const category = useStateCategory();
const filterValues = useStateFilterValues();

const props = defineProps({
    numItems: {
        type: Number,
        required: true,
    },
    pageSizeOptions: {
        type: Array<number>,
        default() {
            return [10, 20, 50, 100];
        },
    },
    additionalParams: {
        type: Object,
        default: null,
    },
});

const pageCount = computed(() => {
    if (!pageSize.value || pageSize.value < 1) return 0;
    return Math.max(Math.ceil(props.numItems / pageSize.value), 1);
});

const isFirstPage = computed(() => page.value <= 1);

const isLastPage = computed(() => page.value >= pageCount.value);

type PageLink = {
    key: string;
    label: string;
    href?: string;
    number?: number;
};

const pageLinks = computed(() => {
    const links: Array<PageLink> = [];

    function addLink(number: number) {
        const label = number.toString();
        links.push({ key: label, label, href: linkHref(number), number });
    }

    let i;
    if (page.value && pageCount.value > MAX_NAV_PAGES) {
        let start = page.value > MEDIAN_NAV_PAGES + 1 ? page.value - MEDIAN_NAV_PAGES + 1 : 1;
        if (start > 2) {
            addLink(1);
            links.push({ key: "prev", label: "..." });
        }

        const end = start + MAX_NAV_PAGES < pageCount.value ? start + MAX_NAV_PAGES - 1 : pageCount.value;
        if (end - start < MAX_NAV_PAGES - 1) {
            start = end - MAX_NAV_PAGES;
        }

        for (i = start; i <= end; i++) {
            addLink(i);
        }

        if (end < pageCount.value) {
            links.push({ key: "next", label: "..." });
            addLink(pageCount.value);
        }
    } else {
        for (i = 1; i <= pageCount.value; i++) {
            addLink(i);
        }
    }

    return links;
});

const additionalQueryParams = computed(() => {
    const { inStock, manufacturers } = filterValues.value;
    const params: Record<string, string> = {
        pageSize: pageSize.value.toString(),
    };
    if (inStock) params.inStock = "true";
    if (!category.value?.Manufacturer && manufacturers.length > 0) params.manufacturers = manufacturers.join(",");

    Object.entries(filterValues.value.specs)
        .filter((f) => f[1].length > 0)
        .forEach(([key, values]) => {
            params[key] = values.join(",");
        });

    return Object.entries(params)
        .map((pair) => pair.map(encodeURIComponent).join("="))
        .join("&");
});

function linkHref(page: number) {
    if (page < 1 || page > pageCount.value) return "";
    return `${route.path}?page=${page}&${additionalQueryParams.value}`;
}

function movePage(amount: number) {
    setPage(page.value + amount);
}

function setPage(newPage?: number) {
    if (newPage !== undefined) page.value = Math.max(Math.min(newPage, pageCount.value), 1);
}
</script>
